import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from "react-helmet";
import {PageProps } from "gatsby";
import SmartphoneCard from '../SmartphoneCard';
import '../SmartphoneCard.css';
import Filter, { FilterHandles } from '../Filter';
import useSaveShopsQuery from "../../../hooks/useSaveShopsQuery";
import shopIcon from '../../../images/shop_Icon.png';
import CustomerReviews from '../CustomerReviews';
import {getLocalStorage, setLocalStorage} from "../../../utils/manageLocalStorage";
import {fetchSmartphoneStock, Smartphone} from "../../../utils/manageSmartphoneStock";
import styled from "styled-components";
import BookingContent from "../../../components/BookingContent";

const FilterWrapper = styled.div`
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        display: block;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        display: none;
    }
`;

const CatalogPage: React.FC<PageProps> = () => {
    const [products, setProducts] = useState<any[]>([]);
    const [filteredProducts, setFilteredProducts] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortOrder] = useState('descending');
    const itemsPerPage = 9;

    const [priceRange, setPriceRange] = useState([0, 1000]);
    const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
    const [selectedModels, setSelectedModels] = useState<string[]>([]);
    const [areFiltersApplied, setAreFiltersApplied] = useState(false);


    const shops = useSaveShopsQuery();

    let pathname = typeof window !== "undefined" ? window.location.pathname : "";
    const shopId = pathname.split('/')[2];

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchAndSetProducts = async () => {

            if (!getLocalStorage(`cacheShopData_${shopId}`)) {
                const data = await fetchSmartphoneStock(shopId);
                setLocalStorage(`cacheShopData_${shopId}`, data);
            }
            const productsList: Smartphone[] = getLocalStorage(`cacheShopData_${shopId}`) ?? [];

            const modeleMap = new Map<string, { minPrice: number, product: any }>();

            for (const product of productsList) {
                const { modele, buyAmountTTC, quantity } = product;
                const normalizedModele = modele.toUpperCase().trim();

                if (!modeleMap.has(normalizedModele)) {
                    modeleMap.set(normalizedModele, {
                        minPrice: buyAmountTTC,
                        product: { ...product, buyAmountTTC, quantity, isNearbyShop: shopId !== product.shopId }
                    });
                } else {
                    const existingEntry = modeleMap.get(normalizedModele)!;
                    existingEntry.product.quantity += quantity;
                    if (buyAmountTTC < existingEntry.minPrice && existingEntry.product.isNearbyShop) {
                        existingEntry.minPrice = buyAmountTTC;
                        existingEntry.product.buyAmountTTC = buyAmountTTC;
                    }
                }
            }

            const updatedProductsList = Array.from(modeleMap.values()).map(entry => entry.product);

            setProducts(updatedProductsList);
            setFilteredProducts(updatedProductsList);
            setLoading(false);
        };

        fetchAndSetProducts();
    }, [shopId]);

    useEffect(() => {
        const sortedProducts = [...products].sort((a, b) => {
            if (a.isNearbyShop && !b.isNearbyShop) return 1;
            if (!a.isNearbyShop && b.isNearbyShop) return -1;
            return sortOrder === 'ascending' ? a.buyAmountTTC - b.buyAmountTTC : b.buyAmountTTC - a.buyAmountTTC;
        });
        setFilteredProducts(sortedProducts);
    }, [sortOrder, products]);

    const handleFilterChange = (filter: { priceRange: number[]; selectedBrands: string[]; selectedModels: string[] }) => {
        setPriceRange(filter.priceRange);
        setSelectedBrands(filter.selectedBrands);
        setSelectedModels(filter.selectedModels);

        const isFiltering = filter.selectedBrands.length > 0 || filter.selectedModels.length > 0 || filter.priceRange[0] > 0 || filter.priceRange[1] < 1000;
        setAreFiltersApplied(isFiltering);

        const filtered = products.filter(product => {
            const meetsPriceCriteria = product.buyAmountTTC >= filter.priceRange[0] && product.buyAmountTTC <= filter.priceRange[1];
            const meetsBrandCriteria = filter.selectedBrands.length === 0 || filter.selectedBrands.includes(product.marque.toUpperCase());
            const meetsModelCriteria = filter.selectedModels.length === 0 || filter.selectedModels.includes(product.modele.toUpperCase());
            return meetsPriceCriteria && meetsBrandCriteria && meetsModelCriteria;
        });

        const sortedFiltered = filtered.sort((a, b) => {
            if (a.isNearbyShop && !b.isNearbyShop) return 1;
            if (!a.isNearbyShop && b.isNearbyShop) return -1;
            return sortOrder === 'ascending' ? a.buyAmountTTC - b.buyAmountTTC : b.buyAmountTTC - a.buyAmountTTC;
        });

        setFilteredProducts(sortedFiltered);
        setCurrentPage(1);
    };


    const filterRef = useRef<FilterHandles>(null);

    const handleRestFilter = () => {
        if (filterRef.current) {
            filterRef.current.resetFilters();
        }
    };

    const handleSortChange = (order: string) => {

        if (areFiltersApplied) {
            const sortedFiltered = [...filteredProducts].sort((a, b) => {
                if (a.isNearbyShop && !b.isNearbyShop) return 1;
                if (!a.isNearbyShop && b.isNearbyShop) return -1;
                return order === 'ascending' ? a.buyAmountTTC - b.buyAmountTTC : b.buyAmountTTC - a.buyAmountTTC;
            });
            setFilteredProducts(sortedFiltered);
        } else {
            const sortedProducts = [...products].sort((a, b) => {
                if (a.isNearbyShop && !b.isNearbyShop) return 1;
                if (!a.isNearbyShop && b.isNearbyShop) return -1;
                return order === 'ascending' ? a.buyAmountTTC - b.buyAmountTTC : b.buyAmountTTC - a.buyAmountTTC;
            });
            setFilteredProducts(sortedProducts);
        }
    };


    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
    const currentItems = filteredProducts.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const validateFilter = () => {
        if (filterRef.current) {
            handleFilterChange({
                priceRange,
                selectedBrands,
                selectedModels,
            });
        }
        setIsModalOpen(false);
    };
    const handleRemoveBrand = (brandToRemove: string) => {
        const updatedBrands = selectedBrands.filter(brand => brand !== brandToRemove);
        handleFilterChange({
            priceRange,
            selectedBrands: updatedBrands,
            selectedModels,
        });
    };

    const handleRemoveModel = (modelToRemove: string) => {
        const updatedModels = selectedModels.filter(model => model !== modelToRemove);
        handleFilterChange({
            priceRange,
            selectedBrands,
            selectedModels: updatedModels,
        });
    };

    const handleResetFilters = () => {
        setPriceRange([0, 1000]);
        setSelectedBrands([]);
        setSelectedModels([]);
        handleFilterChange({
            priceRange: [0, 1000],
            selectedBrands: [],
            selectedModels: [],
        });
    };


    const closeModal = () => {
        setIsModalOpen(false);
    };

    const currentShop = shops.find(shop => shop.shopId === shopId);

    return (
        <>
            <Helmet>
                <title>
                    {currentShop?.shopName
                        ? `Les Smartphones reconditionnés disponibles chez ${currentShop.shopName}`
                        : 'Les Smartphones reconditionnés disponibles'}
                </title>
                <meta
                    name="description"
                    content={
                        currentShop?.shopName
                            ? `Découvrez le catalogue de smartphones reconditionnés de ${currentShop.shopName}. Profitez de grandes marques Apple, Samsung, Huawei et Xiaomi à prix réduits, avec une garantie de qualité et de performance.`
                            : 'Découvrez le catalogue de smartphones reconditionnés. Profitez de grandes marques Apple, Samsung, Huawei et Xiaomi à prix réduits, avec une garantie de qualité et de performance.'
                    }
                />
            </Helmet>
            {!isModalOpen ? (
                <>
                    <span className="recos-title">Les smartphones reconditionnés SAVE</span>
                    <a className="shopName" href="/smartphone">
                        {currentShop?.shopName}
                    </a>
                    <div className="main-container">
                        <FilterWrapper>
                            <Filter
                                productsList={products}
                                priceRange={priceRange}
                                selectedBrands={selectedBrands}
                                selectedModels={selectedModels}
                                onFilterChange={handleFilterChange}
                                ref={filterRef}
                            />
                        </FilterWrapper>
                        {loading ? (
                            <BookingContent.SpinnerContainer>
                                <BookingContent.Spinner/>
                            </BookingContent.SpinnerContainer>
                        ) : (
                            <div className="content-container">

                                    {(selectedBrands.length > 0 || selectedModels.length > 0 || priceRange[0] > 0 || priceRange[1] < 1000) && (
                                        <div className="smart-Number"><b>{filteredProducts.length}</b> produits trouvés</div>
                                    )}
                                <div className="selected-filters">
                                    {selectedBrands.map((brand) => (
                                        <span key={brand} className="filter-chip"
                                              onClick={() => handleRemoveBrand(brand)}>
                                            {brand} ×
                                        </span>
                                    ))}
                                    {selectedModels.map((model) => (
                                        <span key={model} className="filter-chip"
                                              onClick={() => handleRemoveModel(model)}>
                                            {model} ×
                                        </span>
                                    ))}
                                    {(selectedBrands.length > 0 || selectedModels.length > 0 || priceRange[0] > 0 || priceRange[1] < 1000) && (
                                        <a className="removeAllFilter" onClick={handleResetFilters}>
                                            Effacer tous les filtres
                                        </a>
                                    )}
                                </div>


                                <div className="sort-dropdown">
                                <span>
                                    <span className="sort-title">Trier par</span>
                                    <select className="select-sort" onChange={(e) => handleSortChange(e.target.value)}>
                                        <option value="ascending" selected={sortOrder==="ascending"}>Prix croissant</option>
                                        <option value="descending" selected={sortOrder==="descending"}>Prix décroissant</option>
                                    </select>
                                </span>
                                </div>
                                {currentItems.length > 0 ? (
                                    <>
                                        <div className="smartphone-grid">
                                            {currentItems.map((smartphone, index) => (
                                                <SmartphoneCard key={index} {...smartphone} />
                                            ))}
                                        </div>
                                        <div className="pagination">
                                            <button onClick={() => handlePageChange(1)} disabled={currentPage === 1}>
                                                «
                                            </button>
                                            <button onClick={() => handlePageChange(currentPage - 1)}
                                                    disabled={currentPage === 1}>
                                                {'<'}
                                            </button>
                                            {Array.from({length: totalPages}, (_, index) => (
                                                <button
                                                    key={index + 1}
                                                    onClick={() => handlePageChange(index + 1)}
                                                    className={currentPage === index + 1 ? 'active' : ''}
                                                >
                                                    {index + 1}
                                                </button>
                                            ))}
                                            <button onClick={() => handlePageChange(currentPage + 1)}
                                                    disabled={currentPage === totalPages}>
                                                {'>'}
                                            </button>
                                            <button onClick={() => handlePageChange(totalPages)}
                                                    disabled={currentPage === totalPages}>
                                                »
                                            </button>
                                        </div>
                                    </>
                                ) : products.length <= 0 ? (
                                    <div className="no-results">
                                        <p>
                                            Aucun smartphone n’est disponible dans la boutique{' '}
                                            <span className="ShopNameForNotData">{currentShop?.shopName}</span>. Vous
                                            pouvez
                                            changer de magasin pour voir le stock des autres boutiques.
                                        </p>
                                        <a className="noDataButton" href="/smartphone">
                                            <img src={shopIcon} alt="Shop Icon"/> Changer de boutique
                                        </a>
                                    </div>
                                ) : (
                                    <div className="no-results">
                                        <p>
                                            Aucun smartphone ne correspond au résultat de recherche que vous avez
                                            effectué,
                                            afin de découvrir les autres smartphones de la boutique, vous pouvez changer
                                            de
                                            filtre.
                                        </p>
                                        <button className="removeAllFilter" onClick={handleRestFilter}>
                                            Effacer tous les filtres
                                        </button>
                                    </div>
                                )}
                            </div>)}
                    </div>
                    <CustomerReviews shopId={shopId}/>
                    <div className="fixed-button">
                        <button onClick={openModal}>Filter</button>
                    </div>
                </>
            ) : (
                <div className="filter-mobile">
                    <button className="filter-close" onClick={closeModal}></button>
                    <div className="filter-span">Filter</div>
                    <Filter
                        productsList={products}
                        priceRange={priceRange}
                        selectedBrands={selectedBrands}
                        selectedModels={selectedModels}
                        onFilterChange={handleFilterChange}
                        ref={filterRef}
                    />
                    <button onClick={validateFilter} className="gradient-button">Valider les filtres</button>
                </div>
            )}

        </>
    );
};

export default CatalogPage;
